export const materials = [
  {
    img: "COPERTINA_CALZATURA_DONNA",
    EN: "WOMEN'S FOOTWEAR",
    IT: "CALZATURE DONNA",
    FR: "CHAUSSURES POUR FEMMES",
    path: "women_footwear"
  },
  {
    img: "COPERTINA_CALZATURA_UOMO",
    EN: "MEN'S FOOTWEAR",
    IT: "CALZATURE UOMO",
    FR: "CHAUSSURES POUR HOMMES",
    path: "men_footwear"
  },
  {
    img: "COPERTINA_SNEAKERS",
    EN: "SNEAKERS",
    IT: "SNEAKERS",
    FR: "BASKETS",
    path: "sneakers"
  },
  {
    img: "COPERTINA_PELLETTERIA",
    EN: "LEATHER GOODS",
    IT: "PELLETTERIA",
    FR: "MAROQUINERIE",
    path: "leather_goods"
  }
];
/**-----------------------------------------WOMEN--------------------------------------**/
export const WOMEN_FOOTWEAR = {
  mainContext: "women_footwear",
  itemsInMainContext: [
    {
      EN: "Shankboards",
      IT: "Fibrati",
      FR: "MATÉRIAUX FIBRÉS",
      path: "shankboards"
    },
    {
      EN: "Latex foams",
      IT: "Schiume",
      FR: "MOUSSES",
      path: "latex_foams"
    },
    {
      EN: "Reinforcement materials",
      IT: "Materiali da rinforzo",
      FR: "MATÉRIAUX DE RENFORT",
      path: "reinforcement_materials"
    }
  ]
};
export const WOMEN_FOOTWEAR_SHANKBOARDS = [
  {
    EN: "NPU GREEN",
    IT: "NPU GREEN",
    FR: "NPU GREEN",
    path: "npu_green",
    img: "NPU_GREEN"
  },
  {
    EN: "KPB",
    IT: "KPB",
    FR: "KPB",
    path: "kpb",
    img: "KPB"
  },
  {
    EN: "SQN",
    IT: "SQN",
    FR: "SQN",
    path: "sqn",
    img: "SQN"
  },
  // {
  //   EN: "KR1",
  //   IT: "KR1",
  //   path: "kr1",
  //   img: "KR1",
  //   recycle:true,
  // },
  {
    EN: "VIÐARR",
    IT: "VIÐARR",
    FR: "VIÐARR",
    path: "vidarr",
    img: "VIDARR",
    recycle: true
  }
];
export const WOMEN_FOOTWEAR_LATEX_FOAMS = [
  {
    EN: "BRL",
    IT: "BRL",
    FR: "BRL",
    path: "brl",
    img: "BRL"
  },
  {
    EN: "LRC",
    IT: "LRC",
    FR: "LRC",
    path: "lrc",
    img: "LRC"
  },
  {
    EN: "LRCS",
    IT: "LRCS",
    FR: "LRCS",
    path: "lrcs",
    img: "LRCS"
  },

  {
    EN: "HEVEA",
    IT: "HEVEA",
    FR: "HEVEA",
    path: "hevea",
    img: "HEVEA"
  }
];
export const WOMEN_FOOTWEAR_REINFORCEMENT_MATERIALS = [
  {
    EN: "PANAMA",
    IT: "PANAMA",
    FR: "PANAMA",
    path: "panama",
    img: "PANAMA",
    recycle: true
  },
  {
    EN: "BELIZE",
    IT: "BELIZE",
    FR: "BELIZE",
    path: "belize",
    img: "BELIZE",
    recycle: true
  },
  // {
  //   EN: "NICARAGUA",
  //   IT: "NICARAGUA",
  //   FR: "NICARAGUA",
  //   path: "nicaragua",
  //   img: "NICARAGUA",
  //   recycle: true
  // },
  {
    EN: "BIO",
    IT: "BIO",
    FR: "BIO",
    path: "bio",
    img: "BIO",
    recycle: true
  }
  // {
  //   EN: "PK",
  //   IT: "PK",
  //   path: "pk",
  //   img: "PK"
  // }
];

/**-------------------------------------------------MEN-----------------------------------------**/
export const MEN_FOOTWEAR = {
  mainContext: "men_footwear",
  itemsInMainContext: [
    {
      EN: "Shankboards",
      IT: "Fibrati",
      FR: "MATÉRIAUX FIBRÉS",
      path: "shankboards"
    },
    {
      EN: "Latex foams",
      IT: "Schiume",
      FR: "MOUSSES",
      path: "latex_foams"
    },
    {
      EN: "Reinforcement materials",
      IT: "Materiali da rinforzo",
      FR: "MATÉRIAUX DE RENFORT",
      path: "reinforcement_materials"
    }
  ]
};
export const MEN_FOOTWEAR_SHANKBOARDS = [
  {
    EN: "NPU GREEN",
    IT: "NPU GREEN",
    FR: "NPU GREEN",
    path: "npu_green",
    img: "NPU_GREEN"
  },
  {
    EN: "KPB",
    IT: "KPB",
    FR: "KPB",
    path: "kpb",
    img: "KPB"
  },
  {
    EN: "SQN",
    IT: "SQN",
    FR: "SQN",
    path: "sqn",
    img: "SQN"
  },
  {
    EN: "VIÐARR",
    IT: "VIÐARR",
    FR: "VIÐARR",
    path: "vidarr",
    img: "VIDARR",
    recycle: true
  }
  // {
  //   EN: "KR1",
  //   IT: "KR1",
  //   path: "kr1",
  //   img: "KR1"
  // }
];
export const MEN_FOOTWEAR_LATEX_FOAMS = [
  {
    EN: "BRL",
    IT: "BRL",
    FR: "BRL",
    path: "brl",
    img: "BRL"
  },
  {
    EN: "LRC",
    IT: "LRC",
    FR: "LRC",
    path: "lrc",
    img: "LRC"
  },
  {
    EN: "LRCS",
    IT: "LRCS",
    FR: "LRCS",
    path: "lrcs",
    img: "LRCS"
  },
  {
    EN: "HEVEA",
    IT: "HEVEA",
    FR: "HEVEA",
    path: "hevea",
    img: "HEVEA"
  }
];
export const MEN_FOOTWEAR_REINFORCEMENT_MATERIALS = [
  {
    EN: "PANAMA",
    IT: "PANAMA",
    FR: "PANAMA",
    path: "panama",
    img: "PANAMA",
    recycle: true
  },
  {
    EN: "BELIZE",
    IT: "BELIZE",
    FR: "BELIZE",
    path: "belize",
    img: "BELIZE",
    recycle: true
  },
  // {
  //   EN: "NICARAGUA",
  //   IT: "NICARAGUA",
  //   FR: "NICARAGUA",
  //   path: "nicaragua",
  //   img: "NICARAGUA",
  //   recycle: true
  // },
  {
    EN: "BIO",
    IT: "BIO",
    FR: "BIO",
    path: "bio",
    img: "BIO",
    recycle: true
  }
  // {
  //   EN: "PK",
  //   IT: "PK",
  //   path: "pk",
  //   img: "PK"
  // }
];
/**-----------------------------------------SNEAKERS--------------------------------------**/
export const SNEAKERS = {
  mainContext: "sneakers",
  itemsInMainContext: [
    {
      EN: "Latex foams",
      IT: "Schiume",
      FR: "MOUSSES",
      path: "latex_foams"
    },
    {
      EN: "Strobel Materials",
      IT: "Materiali Strobel",
      FR: "MATÉRIAUX STROBEL",
      path: "strobel_materials"
    },
    {
      EN: "Reinforcement materials",
      IT: "Materiali da rinforzo",
      FR: "MATÉRIAUX DE RENFORT",
      path: "reinforcement_materials"
    }
  ]
};
export const SNEAKERS_LATEX_FOAMS = [
  {
    EN: "BRL",
    IT: "BRL",
    FR: "BRL",
    path: "brl",
    img: "BRL"
  },
  {
    EN: "LRC",
    IT: "LRC",
    FR: "LRC",
    path: "lrc",
    img: "LRC"
  },
  {
    EN: "LRCS",
    IT: "LRCS",
    FR: "LRCS",
    path: "lrcs",
    img: "LRCS"
  },
  {
    EN: "HEVEA",
    IT: "HEVEA",
    FR: "HEVEA",
    path: "hevea",
    img: "HEVEA"
  }
];

export const SNEAKERS_STROBEL_MATERIALS = [
  {
    EN: "PIUMA",
    IT: "PIUMA",
    FR: "PIUMA",
    path: "piuma",
    img: "PIUMA"
  },
  {
    EN: "EUCALYPTUS SOFT",
    IT: "EUCALYPTUS SOFT",
    FR: "EUCALYPTUS SOFT",
    path: "eucalyptus_soft",
    img: "EUCALYPTUS_SOFT"
  },
  {
    EN: "EUCALYPTUS STRONG",
    IT: "EUCALYPTUS STRONG",
    FR: "EUCALYPTUS STRONG",
    path: "eucalyptus_strong",
    img: "EUCALYPTUS_STRONG"
  },
  {
    EN: "NUVOLA",
    IT: "NUVOLA",
    FR: "NUVOLA",
    path: "nuvola",
    img: "NUVOLA"
  },
  {
    EN: "HOLLAND",
    IT: "HOLLAND",
    FR: "HOLLAND",
    path: "holland",
    img: "HOLLAND",
    recycle: true
  }
];
export const SNEAKERS_REINFORCEMENT_MATERIALS = [
  {
    EN: "PANAMA",
    IT: "PANAMA",
    FR: "PANAMA",
    path: "panama",
    img: "PANAMA",
    recycle: true
  },
  {
    EN: "BELIZE",
    IT: "BELIZE",
    FR: "BELIZE",
    path: "belize",
    img: "BELIZE",
    recycle: true
  },
  // {
  //   EN: "NICARAGUA",
  //   IT: "NICARAGUA",
  //   FR: "NICARAGUA",
  //   path: "nicaragua",
  //   img: "NICARAGUA",
  //   recycle: true
  // },
  {
    EN: "BIO",
    IT: "BIO",
    FR: "BIO",
    path: "bio",
    img: "BIO",
    recycle: true
  }
];
/**-----------------------------------------LEATHER_GOODS--------------------------------------**/
export const LEATHER_GOODS = {
  mainContext: "leather_goods",
  itemsInMainContext: [
    {
      EN: "Fiiling materials",
      IT: "Materiali da imbottitura",
      FR: "MATÉRIAUX DE REMBOURRAGE",
      path: "fiiling_materials"
    },
    {
      EN: "Reinforcement materials",
      IT: "Materiali da rinforzo",
      FR: "MATÉRIAUX DE RENFORT",
      path: "reinforcement_materials"
    }
  ]
};
export const LEATHER_GOODS_REINFORCEMENT_MATERIALS = [
  {
    EN: "NPU GREEN",
    IT: "NPU GREEN",
    FR: "NPU GREEN",
    path: "npu_green",
    img: "NPU_GREEN"
  },
  {
    EN: "KPB",
    IT: "KPB",
    FR: "KPB",
    path: "kpb",
    img: "KPB"
  },
  {
    EN: "SQN",
    IT: "SQN",
    FR: "SQN",
    path: "sqn",
    img: "SQN"
  },

  {
    EN: "EUCALYPTUS STRONG",
    IT: "EUCALYPTUS STRONG",
    FR: "EUCALYPTUS STRONG",
    path: "eucalyptus_strong",
    img: "EUCALYPTUS_STRONG"
  },
  {
    EN: "VIÐARR",
    IT: "VIÐARR",
    FR: "VIÐARR",
    path: "vidarr",
    img: "VIDARR",
    recycle: true
  },
  {
    EN: "PANAMA",
    IT: "PANAMA",
    FR: "PANAMA",
    path: "panama",
    img: "PANAMA",
    recycle: true
  },
  {
    EN: "BELIZE",
    IT: "BELIZE",
    FR: "BELIZE",
    path: "belize",
    img: "BELIZE",
    recycle: true
  },
  // {
  //   EN: "NICARAGUA",
  //   IT: "NICARAGUA",
  //   FR: "NICARAGUA",
  //   path: "nicaragua",
  //   img: "NICARAGUA",
  //   recycle: true
  // },
  {
    EN: "BIO",
    IT: "BIO",
    FR: "BIO",
    path: "bio",
    img: "BIO",
    recycle: true
  }
];
export const LEATHER_GOODS_FIILING_MATERIALS = [
  {
    EN: "BRL",
    IT: "BRL",
    FR: "BRL",
    path: "brl",
    img: "BRL"
  },
  {
    EN: "LRC",
    IT: "LRC",
    FR: "LRC",
    path: "lrc",
    img: "LRC"
  },
  {
    EN: "LRCS",
    IT: "LRCS",
    FR: "LRCS",
    path: "lrcs",
    img: "LRCS"
  },

  {
    EN: "HEVEA",
    IT: "HEVEA",
    FR: "HEVEA",
    path: "hevea",
    img: "HEVEA"
  },
  {
    EN: "PIUMA",
    IT: "PIUMA",
    FR: "PIUMA",
    path: "piuma",
    img: "PIUMA"
  },
  {
    EN: "NUVOLA",
    IT: "NUVOLA",
    FR: "NUVOLA",
    path: "nuvola",
    img: "NUVOLA"
  },
  {
    EN: "EUCALYPTUS SOFT",
    IT: "EUCALYPTUS SOFT",
    FR: "EUCALYPTUS SOFT",
    path: "eucalyptus_soft",
    img: "EUCALYPTUS_SOFT"
  },
  {
    EN: "HOLLAND",
    IT: "HOLLAND",
    FR: "HOLLAND",
    path: "holland",
    img: "HOLLAND",
    recycle: true
  }
];
